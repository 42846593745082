<template>
  <ValidationProvider tag="div" class="column is-half" :rules="validationRules" v-slot="{ errors, dirty, valid }">
    <div class="question-title">
      <span v-if="showQuestionCode">{{ question.code }}: </span><span v-html="question.question[lang]"></span>
      <p v-if="errors[0]" class="help is-danger">{{ errors[0] }}</p>
    </div>
    <b-field :type="{ 'is-danger': errors[0], 'is-success': dirty && valid }">
      <b-input
          v-autogrow
          type="textarea"
          :value="value[question.qid]"
          :disabled="loading"
          custom-class="fa-input"
          @input="$emit('input', { [question.qid]: $event })"
      ></b-input>
    </b-field>
  </ValidationProvider>
</template>
<script>

import { QuestionMixin } from '@/mixins/question'
import { TextareaAutogrowDirective } from '@/directives/textarea-auto-grow.directive'

export default {
  name: 'FreeAnswer',
  mixins: [QuestionMixin],
  directives: {
    'autogrow': TextareaAutogrowDirective
  },
}
</script>
<style lang="scss">
textarea.fa-input {
  padding: 1.5rem;
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  border: none;
}
</style>
